import IExtension from "./IExtension";
import {Naja} from "naja";

export default class PreventDoubleSubmitExtension implements IExtension {

    initialize(naja: Naja) {
        naja.addEventListener('init', () => this.notAjaxInit($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.notAjaxInit($(<HTMLElement>e.detail.snippet)));

        naja.addEventListener('before', event=> {
            if (event.currentTarget) {
                const $el = $(event.currentTarget);
                if ($el.is('input[type="submit"]')) {
                    $el.attr('disabled', 1);
                }
            }
        });

        naja.addEventListener('complete', event => {
            if (event.currentTarget) {
                const $el = $(event.currentTarget);
                if ($el.is('input[type="submit"]')) {
                    $el.prop('disabled');
                }
            }
        })
    }

    private notAjaxInit($root: JQuery): void {
        $root.find('input[type="submit"][data-prevent-double-submit], button[type="submit"][data-prevent-double-submit]')
            .each((i, input) => {
                const $input = $(input);
                const form = <HTMLFormElement>$input.closest('form').get(0);

                form?.addEventListener('submit', e => {
                    if (e.defaultPrevented === false) {
                        input.classList.add('disabled');
                    }
                })
            })

    }

}

